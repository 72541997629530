import React from 'react';
// import { Link } from 'gatsby'
import { useForm } from 'react-hook-form';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { Container, Row, Col } from 'react-grid-system';
import Hero from '../components/rows/hero/index';

import Banner from '../images/hero-images/research-hero.jpg';

export default function PatientReferralForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    if (errors) {
      console.log(
        '🚀 ~ file: patient-referral-form.js:17 ~ PatientReferralForm ~ errors',
        errors
      );
    }
    // fetch('/.netlify/functions/patient-referral-form', {
    //     method: 'POST',
    //     headers: {
    //         'Accept': 'application/json, text/plain, */*',
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(data)
    //     }).then(res => res.json())
    //     .then(res => console.log(res));
  };

  const cardiologyProcedures = [
    {
      title: 'Adult ECHO',
      key: 'adultEcho',
    },
    {
      title: 'Contrast ECHO',
      key: 'contrastECHO',
    },
    {
      title: 'Stress ECHO',
      key: 'stressECHO',
    },
    {
      title: 'Stress Test',
      key: 'stressTest',
    },
    {
      title: '12 Lead ECG',
      key: 'leadECG',
    },
    {
      title: '24 hrs. Holter Monitor',
      key: '24holterMonitor',
    },
    {
      title: '48 hrs. Holter Monitor',
      key: '48holterMonitor',
    },
    {
      title: '72 hrs. Holter Monitor',
      key: '72holterMonitor',
    },
    {
      title: '7 day Holter Monitor',
      key: '7dayHolterMonitor',
    },
    {
      title: '14 day Holter Monitor',
      key: '14dayHolterMonitor',
    },
    {
      title: '24 hrs ABP Monitor ($60)',
      key: '24hrsABPMonitor',
    },
  ];

  const electrophysiology = [
    {
      title: 'Cardioversion',
      key: 'cardioversion',
    },
    {
      title: 'Permanent Pacemaker',
      key: 'permanentPacemaker',
    },
    {
      title: 'Leadless',
      key: 'leadless',
    },
    {
      title: 'ICD (Implantable Defibrillator)',
      key: 'ICD',
    },
    {
      title: 'Implantable Loop Recorder',
      key: 'implantableLoopRecorder',
    },
    {
      title: 'Left Bundle Branch pacing',
      key: 'leftBundleBranchpacing',
    },
    {
      title: 'Electrophysiology Study/Ablation',
      key: 'electrophysiologyStudyAblation',
    },
    {
      title: 'Biventricular / Cardiac resynchronization therapy',
      key: 'biventricularCardiacresynchronizationtherapy',
    },
  ];

  const pediatricCardiology = [
    {
      title: 'Pediatric ECG',
      key: 'pediatricECG',
    },
    {
      title: 'Pediatric ECHO',
      key: 'pediatricECHO',
    },
    {
      title: 'Pediatric Holter Monitor',
      key: 'pediatricHolterMonitor',
    },
    {
      title: '24 Hour',
      key: '24Hour',
    },
    {
      title: '48 Hour',
      key: '48Hour',
    },
    {
      title: '7 Day',
      key: '7day',
    },
    {
      title: '14 day',
      key: '14day',
    },
  ];

  return (
    <Layout>
      <Seo title="Patient Referral" description="Patient Referral." />
      <Hero title="Patient Referral" banner={Banner} />
      <Container className="my-52">
        <Row>
          <Col md={6} sm={12} className="mx-auto">
            <h2 className="text-center">
              <span className="text-red-300">PACE</span> patient referral form
            </h2>
            <p className="text-center">
              PACE's team of physicians includes specialists in General
              Cardiology, Electrophysiology /Arrhythmia, interventional
              Cardiology, Internal Medicine, Cardiac Genetics, and Diabetes.
            </p>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="firstName"
            >
              First Name
            </label>
            <input
              type="text"
              name="First Name"
              id="firstName"
              {...register('First Name', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="lastName"
            >
              Last Name
            </label>
            <input
              type="text"
              name="Last Name"
              id="lastName"
              {...register('Last Name', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="pronouns"
            >
              What are your pronouns?
            </label>
            <select
              {...register('pronouns', { required: true })}
              id="pronouns"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="She/Her">She/Her</option>
              <option value="He/Him">He/Him</option>
              <option value="They/It">They/It</option>
            </select>
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="birth"
            >
              Date of Birth
            </label>
            <input
              type="datetime-local"
              name="Birth"
              id="birth"
              {...register('Birth', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="healhCard"
            >
              Health Card #
            </label>
            <input
              type="text"
              name="HealthCard"
              {...register('HealthCard', {})}
              id="healthCard"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="vrs"
            >
              Vrs. Code
            </label>
            <input
              type="text"
              name="Vrs"
              id="vrs"
              {...register('Vrs', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="phone"
            >
              Main Phone #
            </label>
            <input
              type="tel"
              name="MainPhone"
              id="phone"
              {...register('MainPhone', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              name="Email"
              id="email"
              {...register('Email', {})}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label htmlFor="appointmentDate">Appointment Date</label>
            <input
              type="datetime-local"
              name="apptDate"
              id="appointmentDate"
              {...register('apptDate', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div className="input">
            <label
              className="block font-medium text-gray-700 required"
              htmlFor="location"
            >
              Appointment Location
            </label>
            <select
              {...register('location', { required: true })}
              id="location"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="Barrie">Barrie</option>
              <option value="Newmarket">Newmarket</option>
              <option value="Orillia">Orillia</option>
            </select>
          </div>
          <div>
            <p>1. Cardiology procedures</p>
            {cardiologyProcedures.map((check, id) => (
              <div key={id} className="checkboxes">
                <label htmlFor={`${check.key}`}>{check.title}</label>
                <input
                  {...register('procedures', {})}
                  type="checkbox"
                  id={`${check.key}`}
                  name={`${check.key}`}
                  value={`${check.title}`}
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
            ))}
          </div>
          <div>
            <p>2. Electrophysiology</p>
            {electrophysiology.map((check, id) => (
              <div key={id} className="checkboxes">
                <label htmlFor={`${check.key}`}>{check.title}</label>
                <input
                  {...register('electrophysiology', {})}
                  type="checkbox"
                  id={`${check.key}`}
                  name={`${check.key}`}
                  value={`${check.title}`}
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
            ))}
          </div>
          <div>
            <p>3. Pediatric Cardiology</p>
            {pediatricCardiology.map((check, id) => (
              <div key={id} className="checkboxes">
                <label htmlFor={`${check.key}`}>{check.title}</label>
                <input
                  {...register('pediatricCardiology', {})}
                  type="checkbox"
                  id={`${check.key}`}
                  name={`${check.key}`}
                  value={`${check.title}`}
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
            ))}
          </div>
          <div>
            <p>
              4. <span className="text-red-300">Doctor Consultation**</span>
            </p>
            <span className="radio">
              <label htmlFor="Elective">Elective</label>
              <input
                {...register('doctorConsults', {})}
                type="radio"
                id="Elective"
                name="consultation"
                value="Elective"
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
            </span>
            <span className="radio">
              <label htmlFor="abnormal">
                If test is abnormal please arrange for a consultation
              </label>
              <input
                {...register('doctorConsults', {})}
                type="radio"
                id="abnormal"
                name="consultation"
                value="abnormal"
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
            </span>
            <span className="radio">
              <label htmlFor="URGENT">
                <strong>URGENT</strong>
              </label>
              <input
                {...register('doctorConsults', {})}
                type="radio"
                id="URGENT"
                name="consultation"
                value="URGENT"
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
            </span>
          </div>
          <div>
            <p>5. Cardio Me Tabolic Clinic</p>
            <span className="checkboxes w-full">
              <label htmlFor="theme">
                The clinic's theme is the management of Type II Diabetes in
                patients with documented vascular disease to improve glycemic
                control and reduce further cardiac complications. Our physicians
                will work collaboratively with the patient's GPs and/or
                endocrinologists.
              </label>
              <input
                {...register('cardioMe', {})}
                type="checkbox"
                id="theme"
                name="theme"
                value="theme"
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
            </span>
          </div>
          <div>
            <p>6. Reason for referral</p>
            <textarea
              {...register('mainSymptoms', { required: true })}
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md"
            />
            <p>
              Requests for consultations should include the reason for referral,
              medication list and any previous ECGs, chest x-rays, blood work
              and cardiac assessment. <span className="text-red-300">**</span>
              Please be advised we will send you a fax confirmation with your
              patient's appointment date and time - please contact your patient
              with this information.
            </p>
          </div>
          <div className="button-wrapper">
            <button type="submit" className="form-button red-button">
              Submit Form
            </button>
            <button className="form-button blue-button">Download PDF</button>
          </div>
        </form>
      </Container>
    </Layout>
  );
}
